<template>
    <v-dialog
      v-model="$store.state.app.show_session_expiration_dialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Session expired
        </v-card-title>

        <v-card-text>
          This session expired as you logged in with another account. Refresh page to continue.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="refresh"
          >
            Refresh
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>



export default {
  components: {
    
  },
  data(){
    return {
      dialog: true
    }
  },
  computed: {
    
  },
  methods: {
    refresh(){
      location.reload()
    }
  },
  mounted(){
    
  }
}
</script>